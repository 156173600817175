::-webkit-scrollbar {
    width: 14px;
}

/* Track */
::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 20px;
}

::-webkit-scrollbar-corner {
    background: transparent;
}
/* Handle */
::-webkit-scrollbar-thumb {
    @apply bg-gray-500;
    border: 4px solid rgb(24, 24, 27);
    border-top: 0px;
    border-left: 0px;
    border-bottom: 0px;
}

.swal2-html-container {
    @apply m-0 rounded-xl !important;
}
